exports.components = {
  "component---src-pages-shopstory-canvas-tsx": () => import("./../../../src/pages/shopstory-canvas.tsx" /* webpackChunkName: "component---src-pages-shopstory-canvas-tsx" */),
  "component---src-templates-404-404-js": () => import("./../../../src/templates/404/404.js" /* webpackChunkName: "component---src-templates-404-404-js" */),
  "component---src-templates-about-about-tsx": () => import("./../../../src/templates/about/about.tsx" /* webpackChunkName: "component---src-templates-about-about-tsx" */),
  "component---src-templates-blog-news-blog-news-js": () => import("./../../../src/templates/blog-news/blog-news.js" /* webpackChunkName: "component---src-templates-blog-news-blog-news-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-customer-stories-overview-customer-stories-overview-js": () => import("./../../../src/templates/customerStoriesOverview/customerStoriesOverview.js" /* webpackChunkName: "component---src-templates-customer-stories-overview-customer-stories-overview-js" */),
  "component---src-templates-customerstories-customer-story-js": () => import("./../../../src/templates/customerstories/CustomerStory.js" /* webpackChunkName: "component---src-templates-customerstories-customer-story-js" */),
  "component---src-templates-demo-request-demo-request-jsx": () => import("./../../../src/templates/demoRequest/demoRequest.jsx" /* webpackChunkName: "component---src-templates-demo-request-demo-request-jsx" */),
  "component---src-templates-faq-faq-jsx": () => import("./../../../src/templates/FAQ/FAQ.jsx" /* webpackChunkName: "component---src-templates-faq-faq-jsx" */),
  "component---src-templates-feature-detail-page-feature-detail-page-jsx": () => import("./../../../src/templates/FeatureDetailPage/FeatureDetailPage.jsx" /* webpackChunkName: "component---src-templates-feature-detail-page-feature-detail-page-jsx" */),
  "component---src-templates-feature-overview-feature-overview-jsx": () => import("./../../../src/templates/FeatureOverview/FeatureOverview.jsx" /* webpackChunkName: "component---src-templates-feature-overview-feature-overview-jsx" */),
  "component---src-templates-free-trial-page-free-trial-page-jsx": () => import("./../../../src/templates/FreeTrialPage/FreeTrialPage.jsx" /* webpackChunkName: "component---src-templates-free-trial-page-free-trial-page-jsx" */),
  "component---src-templates-landing-page-landing-page-jsx": () => import("./../../../src/templates/landingPage/landingPage.jsx" /* webpackChunkName: "component---src-templates-landing-page-landing-page-jsx" */),
  "component---src-templates-legalpages-legalpages-js": () => import("./../../../src/templates/legalpages/legalpages.js" /* webpackChunkName: "component---src-templates-legalpages-legalpages-js" */),
  "component---src-templates-new-homepage-new-homepage-jsx": () => import("./../../../src/templates/NewHomepage/NewHomepage.jsx" /* webpackChunkName: "component---src-templates-new-homepage-new-homepage-jsx" */),
  "component---src-templates-news-insights-overview-news-insights-overview-jsx": () => import("./../../../src/templates/newsInsightsOverview/newsInsightsOverview.jsx" /* webpackChunkName: "component---src-templates-news-insights-overview-news-insights-overview-jsx" */),
  "component---src-templates-partner-partner-tsx": () => import("./../../../src/templates/partner/partner.tsx" /* webpackChunkName: "component---src-templates-partner-partner-tsx" */),
  "component---src-templates-pricing-pricing-page-js": () => import("./../../../src/templates/pricing/pricingPage.js" /* webpackChunkName: "component---src-templates-pricing-pricing-page-js" */),
  "component---src-templates-promise-promise-tsx": () => import("./../../../src/templates/promise/promise.tsx" /* webpackChunkName: "component---src-templates-promise-promise-tsx" */),
  "component---src-templates-search-page-search-page-jsx": () => import("./../../../src/templates/searchPage/searchPage.jsx" /* webpackChunkName: "component---src-templates-search-page-search-page-jsx" */),
  "component---src-templates-trial-ended-call-you-asap-js": () => import("./../../../src/templates/trial-ended/call-you-asap.js" /* webpackChunkName: "component---src-templates-trial-ended-call-you-asap-js" */)
}

